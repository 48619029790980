import {DEFAULT_PRICE_DELIMITER, ROUND_OPTION} from '../const';
import {isDemoMode, isUnsupportedLanguage, reverseString} from '../utils/tools';
import {LANG_SETTING} from '../settings';

export const roundPrice = (price) => {
    const {UsePriceRound} = LANG_SETTING[MODULE_PRICE_LOAD_LANG];

    if (UsePriceRound !== ROUND_OPTION.No) {
        switch (UsePriceRound) {
            case ROUND_OPTION.RoundDown:
                return Math.floor(price);
            case ROUND_OPTION.RoundUp:
                return Math.ceil(price);
            case ROUND_OPTION.RoundNearest:
                return Math.round(price);
            default:
                break;
        }
    }

    return price;
};

export const splitPrice = (price) => {
    const {UsePriceSplit} = LANG_SETTING[MODULE_PRICE_LOAD_LANG];

    if (UsePriceSplit) {
        price = `${price}`;

        const priceEndingRegex = price.match(/(([,.])\d+$)/);
        const priceEnding = priceEndingRegex ? priceEndingRegex[0] : '';
        const priceMain = price.replace(priceEnding, '');

        price = reverseString(priceMain)
            .match(/.{1,3}/g)
            .map((character) => {
                return reverseString(character);
            })
            .reverse()
            .join(' ');

        price += priceEnding;
    }

    return price;
};

export const changePriceDelimiter = (price) => {
    const {PriceDelimiter} = LANG_SETTING[MODULE_PRICE_LOAD_LANG];

    return PriceDelimiter !== ''
        ? `${price}`.replace(DEFAULT_PRICE_DELIMITER, PriceDelimiter)
        : price;
};

export const getFormatPrice = (price) => {
    if (isDemoMode() || isUnsupportedLanguage()) {
        return price;
    }

    price = roundPrice(price);
    price = splitPrice(price);
    price = changePriceDelimiter(price);

    return price;
};
