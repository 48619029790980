import {PLACEHOLDER} from '../const';
import {isDemoMode, isUnsupportedLanguage} from '../utils/tools';
import {LANG_SETTING} from '../settings';
import {DICTIONARY, EXTRA_DICTIONARY} from '../dictionary';

export const getRuMinLosText = (minlos) => {
    const extraDictionary = EXTRA_DICTIONARY['ru'];
    const minLosEnd = +(minlos.toString()[minlos.toString().length - 1]);

    let resultMinLosText = LANG_SETTING[MODULE_PRICE_LOAD_LANG].MinLosText;
    let minLosNumeralEnd = '';
    let minLosDay = '';
    let isExceptionNumber = false;

    for (const numbersGroup of extraDictionary.numeralEnds.byNumbers) {
        const [numbers, langInfo] = numbersGroup;
        const {end: numeralEnd, day} = langInfo;

        if (numbers.includes(minlos)) {
            minLosNumeralEnd = numeralEnd;
            minLosDay = day;
            isExceptionNumber = true;
            break;
        }
    }

    if (!isExceptionNumber) {
        for (const numberEndsGroup of extraDictionary.numeralEnds.byNumberEnds) {
            const [numberEnd, langInfo] = numberEndsGroup;
            const {end: numeralEnd, day} = langInfo;

            if (numberEnd.includes(minLosEnd)) {
                minLosNumeralEnd = numeralEnd;
                minLosDay = day;
                break;
            }
        }
    }

    resultMinLosText = resultMinLosText.replace(PLACEHOLDER.MinLosEnd, minLosNumeralEnd);
    resultMinLosText = resultMinLosText.replace(PLACEHOLDER.MinLosDay, minLosDay);
    resultMinLosText = resultMinLosText.replace(PLACEHOLDER.MinLos, minlos);

    return resultMinLosText;
};

export const getDefaultMinLosText = (minlos) => {
    const minLosDay = minlos === 1
        ? DICTIONARY[MODULE_PRICE_LOAD_LANG].day.one
        : DICTIONARY[MODULE_PRICE_LOAD_LANG].day.many;
    let minLosText = LANG_SETTING[MODULE_PRICE_LOAD_LANG].MinLosText;

    minLosText = minLosText.replace(PLACEHOLDER.MinLosDay, minLosDay);
    minLosText = minLosText.replace(PLACEHOLDER.MinLos, minlos);

    return minLosText;
};

export const getMinLosText = (minlos) => {
    if (MODULE_PRICE_LOAD_LANG !== 'ru') {
        return getDefaultMinLosText(minlos);
    } else {
        return getRuMinLosText(minlos);
    }
};

export const getLangMinLos = (minlos) => {
    if (isDemoMode() || isUnsupportedLanguage()) {
        return minlos;
    }

    return minlos ? getMinLosText(minlos) : minlos;
};
