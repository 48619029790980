import {DEFAULT_ARRAY_INDEX} from '../const';

export class PriceInfo {
    constructor(priceInfo) {
        this._priceInfo = priceInfo;
    }

    getRestoreStatus() {
        return this._priceInfo['isRestored'];
    }

    getFillStatus() {
        return this._priceInfo['isFilled'];
    }

    getRatePlan() {
        return this._priceInfo['ratePlan'] ? this._priceInfo['ratePlan'] : null;
    }

    getRatePlaneCode() {
        const ratePlan = this.getRatePlan();

        return ratePlan ? ratePlan['code'] : '';
    }

    getData() {
        return this._priceInfo['date'];
    }

    getMinLos() {
        return this._priceInfo['minLos'];
    }

    getRatePlaneName() {
        const ratePlan = this.getRatePlan();

        if (ratePlan) {
            const ratePlanNames = ratePlan['names'];
            const availableLangs = Object.keys(ratePlanNames);

            return ratePlanNames[MODULE_PRICE_LOAD_LANG]
                ? ratePlanNames[MODULE_PRICE_LOAD_LANG]
                : ratePlanNames[availableLangs[DEFAULT_ARRAY_INDEX]];
        }

        return '';
    }

    getPrice() {
        return Object.values(this._getPrices())[DEFAULT_ARRAY_INDEX];
    }

    getPriceByCurrency(currency) {
        const price = this._getPrices();

        if (price) {
            return price[currency]
                ? price[currency]
                : '';
        }

        return '';
    }

    isEmpty() {
        return Boolean(this._priceInfo);
    }

    _getPrices() {
        return this._priceInfo['price'];
    }
}
