import {PLACEHOLDER} from '../const';
import {isDemoMode, isUnsupportedLanguage} from '../utils/tools';
import {LANG_SETTING} from '../settings';

export const getOfferText = (offer) => {
    return LANG_SETTING[MODULE_PRICE_LOAD_LANG].OfferText.replace(PLACEHOLDER.Offer, offer);
};

export const getLangOffer = (offer) => {
    if (isDemoMode() || isUnsupportedLanguage()) {
        return offer;
    }

    return (offer) ? getOfferText(offer) : offer;
};
